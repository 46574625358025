<template>
  <vue-tel-input
  :preferredCountries="['US']"
  invalidMsg="Phone number is invalid!"
  :inputOptions="{required: true, placeholder: 'Phone *'}"
  validCharactersOnly
  v-model="initialPhone"
  @input="assignPhone"
  id="phone-input"
  >
  </vue-tel-input>
</template>

<script>
const VueTelInput = () =>
  Promise.all([
    import(/* webpackChunkName: "chunk-vue-tel-input" */ 'vue-tel-input'),
    import(/* webpackChunkName: "chunk-vue-tel-input" */ 'vue-tel-input/dist/vue-tel-input.css')
  ]).then(([{ VueTelInput }]) => VueTelInput)
// import { VueTelInput } from 'vue-tel-input'
// import 'vue-tel-input/dist/vue-tel-input.css'

export default {
  props: ['phone'],
  components: {
    // VuePhoneNumberInput
    VueTelInput
  },
  data () {
    return {
      initialPhone: ''
    }
  },
  methods: {
    assignPhone (phone, phoneObj) {
      this.$emit('update', phoneObj)
    }
  },
  mounted () {
    if (this.phone) this.initialPhone = this.phone
  }
}
</script>

<style>
.vue-tel-input {
  height: 60px;
  border: #9e9e9e 1px solid !important;
}
</style>
