<template>
    <div>
        <div v-for="(item, i) in flight.pricingInfo.breakDowns" :key="i+'price'">
            <p class="grey lighten-2 paragraph--text text-center text-h6 font-weight-regular mb-0">Passenger</p>
            <p class="body-1 paragraph--text ml-5 my-5">
                {{item.passengerQuantity}}
                <template v-for="type in types">
                <span :key="type.code" v-if="type.code === item.passengerType">{{type.label}}</span>
                </template>
                (s)
            </p>
            <v-row justify="space-between" class="mx-5 my-1">
                <p class="body-1 paragraph--text mb-1">Basic fare</p>
                <p class="body-1 paragraph--text mb-1"><strong>${{item.baseFare}}</strong></p>
            </v-row>
            <v-row justify="space-between" class="mx-5 mb-1">
                <p class="body-1 paragraph--text mb-1">Taxes per person</p>
                <p class="body-1 paragraph--text mb-1"><strong>${{item.taxes}}</strong></p>
            </v-row>
            <v-row justify="space-between" class="mx-5 mb-1">
                <p class="body-1 paragraph--text mb-1">Price per person</p>
                <p class="body-1 paragraph--text mb-1"><strong>${{parseFloat(item.totalFare).toFixed(2)}}</strong></p>
            </v-row>
        </div>
    </div>
</template>

<script>
export default {
  props: ['flight'],
  data () {
    return {
      types: [
        { label: 'Adult', code: 'ADT' },
        { label: 'Child', code: 'CNN' },
        { label: 'Infant', code: 'INF' }
      ]
    }
  }
}
</script>
