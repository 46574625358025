<template>
    <div :class="$store.state.mode === 'ats' || $store.state.mode === 'adam' || $store.state.mode === 'atsDev' || $store.state.mode === 'adamDev' ? 'ats' : 'fare'">
        <v-progress-linear
        indeterminate
        color="primary"
        height="10"
        top
        :active="loaded"
        ></v-progress-linear>
        <v-row justify="center" class="mt-15" align="center" v-if="($store.state.mode === 'ats' || $store.state.mode === 'atsDev') && loaded" >
          <v-progress-circular
          :size="70"
          :width="7"
          color="primary"
          indeterminate
          ></v-progress-circular>
        </v-row>

        <overlay :flight="flight" @flightAvailable="replaceFlight" @flightNotAvailable="flightCheckFailed" v-if="flightsOverlay && el !== 3" />
        <v-snackbar id="snackbar" v-model="snackbar" :color="color" top :timeout="5000">
          <v-row>
            {{ text }} <router-link to="/" style="color: blue; margin-left: 5px;"> Search again</router-link>
            <v-spacer></v-spacer>
            <v-btn
              dark
              icon
              @click="snackbar = false"
            >
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </v-row>
        </v-snackbar>
        <v-overlay :value="showOverlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-container v-if="loaded === false && flight === null">
           <v-row justify='center' class="pt-5 mt-5"><p class="display-1 primary--text">This flight is not available, please search again in few minutes.</p></v-row>
        </v-container>
        <v-container class="px-0" fluid v-if="loaded === false && flight !== null">
          <!-- <v-row v-if="flight !== null" justify="center" class="pr-3 my-5"> -->
            <v-row justify="space-around" class="my-5">
              <v-btn elevation="0" class="rounded-lg" :text="step !== 1" color="primary" x-large @click="step = 1">Flight summary</v-btn>
              <v-btn elevation="0" class="rounded-lg" :text="step !== 2" color="primary" x-large @click="step = 2">traveller information</v-btn>
              <v-btn elevation="0" class="rounded-lg" :text="step !== 3" color="primary" x-large @click="nextClicked(3)">payment</v-btn>
              <v-btn elevation="0" class="rounded-lg" :text="step !== 4" color="primary" x-large @click="step = 4">confirmation</v-btn>
            </v-row>
            <div fluid v-if="flight !== null">
              <div v-show="step === 1">
                <div class="background">
                  <v-container>
                    <flight-card class="mt-9" :flights="flightCard"/>
                  </v-container>
                </div>
                <v-row justify="center" class="my-5">
                  <v-btn x-large class="mx-5" color="white primary--text" @click="$router.go(-1)">Back to results</v-btn>
                  <v-btn x-large class="mx-5 px-10" color="primary white--text" @click="step = 2">Next</v-btn>
                </v-row>
              </div>
              <div v-show="step === 2">
                <v-container fluid class="px-15">
                  <v-row>
                    <v-col cols="12" md="8">
                      <v-form v-model="valid1" ref="form1">
                        <v-card outlined class="pa-2 pt-3 mb-5" v-if="showContactFields">
                          <h2 class="paragraph--text font-weight-bold">Contact info for all passengers</h2>
                          <p class="paragraph--text">*Field Required</p>
                          <v-row>
                            <v-col cols="12" sm="4" md="3">
                              <v-text-field
                              height="60"
                              outlined
                              v-model="name"
                              :rules="[v => !!v || 'Item is required', v => /^[a-zA-Z\s]*$/.test(v) || 'Name Must be letters only']"
                              label="Full Name *"
                              required
                              color="blue"
                              id="passenger-fullname"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                              <phoneInput :phone="phone.nationalNumber" @update="assignPhone" />
                            </v-col>
                            <v-col cols="12" sm="4" md="5">
                              <v-text-field
                              height="60"
                              outlined
                              v-model="email"
                              :rules="emailRules"
                              label="E-mail *"
                              required
                              color="blue"
                              id="passenger-email"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <!-- <p class="caption">* We'll use this email to contact you about your booking!</p> -->
                        </v-card>
                        <v-card v-for="n in passengersNum" :key="n" outlined class="mb-5">
                          <h2 class="paragraph--text pa-2 font-weight-bold">passenger {{n}}, {{adultsNum >= n ? `Adult ${infantsNum >= n ? 'With infant in lap' : ''}` : (childrenNum >= ( n - adultsNum) ? 'Child' : (infantsNum >= (n - (adultsNum + childrenNum)) ? 'Infant' : null))}}</h2>
                          <p class="pl-2 paragraph--text">*Field Required</p>
                          <v-radio-group hide-details class="pl-2 mb-3" row v-model="passengerTitle[n-1]">
                            <v-radio
                            label="Mr"
                            value="Mr"
                            class="mr-5"
                            ></v-radio>
                            <v-radio
                            label="Miss"
                            value="Miss"
                            ></v-radio>
                          </v-radio-group>
                          <v-row class="pa-2 pb-5">
                            <v-col cols="12" sm="4">
                              <v-text-field
                              height="60"
                              hide-details
                              outlined
                              prepend-inner-icon="mdi-account"
                              v-model="passengerFirstName[n-1]"
                              :rules="nameRules"
                              label="First name"
                              required
                              color="blue"
                              :id="`passenger-${n}-firstName`"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <v-text-field
                              height="60"
                              hide-details
                              outlined
                              prepend-inner-icon="mdi-account"
                              v-model="passengerMiddleName[n-1]"
                              label="Middle name:Optional"
                              color="blue"
                              :id="`passenger-${n}-middleName`"
                              :rules="middleNameRules"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <v-text-field
                              height="60"
                              hide-details
                              outlined
                              v-model="passengerLastName[n-1]"
                              prepend-inner-icon="mdi-account"
                              :rules="nameRules"
                              label="Last name"
                              required
                              color="blue"
                              :id="`passenger-${n}-lastName`"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <date-picker
                              v-model="dateOfBirth[n-1]"
                              clearable
                              editable
                              valueType="YYYY-MM-DD"
                              placeholder="Birthday"
                              :default-value="adultsNum >= n ? adultsMaxDate : ((childrenNum >= (n - adultsNum)) ? childrenMinDate :  infantsMinDate)"
                              style="width: 100%; height: 60px;"
                              :disabled-date="adultsNum >= n ? adultsBirthDateRange : ((childrenNum >= (n - adultsNum)) ? childrenBirthDateRange :  infantsBirthDateRange)"
                              required
                              format="M/D/YYYY"
                              append-to-body
                              :input-attr="{id: 'birthDateId'+n}"
                              @focus="changeInputColor('birthDateId'+n, '#2196F3')"
                              @blur="changeInputColor('birthDateId'+n, '#9e9e9e')"
                              @input-error="changeInputColor('birthDateId'+n, 'red')"
                              :id="`passenger-${n}-birthday`"
                              >
                              </date-picker>
                              <!-- <span class="caption grey--text">Hint: MM/DD/YYYY</span> -->
                            </v-col>
                            <v-col cols="12" sm="4">
                              <v-text-field
                              height="60"
                              v-model="passNum[n-1]"
                              :label="flight.isDomesticFlight ? 'Passport Number:Optional' : 'Passport Number'"
                              prepend-inner-icon="mdi-passport"
                              outlined
                              :rules="passRules"
                              :hint="flight.isDomesticFlight ? 'Optional Field' : ''"
                              persistent-hint
                              color="blue"
                              hide-details
                              :id="`passenger-${n}-passport-num`"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <date-picker
                              v-model="passExpireDate[n-1]"
                              clearable
                              editable
                              valueType="YYYY-MM-DD"
                              :placeholder="flight.isDomesticFlight ? 'Passport Expire Date:Optional' : 'Passport Expire Date'"
                              :default-value="passportMinExpireDate"
                              style="width: 100%; height: 60px;"
                              :disabled-date="minExpireDateRange"
                              format="M/D/YYYY"
                              append-to-body
                              :input-attr="{id: 'passportExpiry'+n}"
                              @focus="changeInputColor('passportExpiry'+n, '#2196F3')"
                              @blur="changeInputColor('passportExpiry'+n, '#9e9e9e')"
                              @input-error="changeInputColor('passportExpiry'+n, 'red')"
                              :id="`passenger-${n}-passportExpiry`"
                              >
                              </date-picker>
                              <!-- <span class="caption grey--text">{{flight.isDomesticFlight ? 'Hint: Optional Field MM/DD/YYYY' : 'Hint: MM/DD/YYYY}'}}</span> -->
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-autocomplete
                                v-model="issueCountry[n-1]"
                                :items="countries"
                                item-text="name"
                                item-value="code"
                                :placeholder="flight.isDomesticFlight ? 'Issuing Country:Optioanl' : 'Issuing Country'"
                                :hint="flight.isDomesticFlight ? 'Optional Field' : ''"
                                persistent-hint
                                name="issue-country-for-passports"
                                required
                                outlined
                                prepend-inner-icon="mdi-flag"
                                hide-no-data
                                color="blue"
                                autocomplete="off"
                                height="60"
                                hide-details
                                :menu-props="{ auto: true, maxWidth: 200, overflowY: true }"
                                :id="`passenger-${n}-passport-issue-country`"
                                ></v-autocomplete>
                            </v-col>
                          </v-row>
                          <v-divider></v-divider>
                          <v-card-actions>
                            <v-expansion-panels flat>
                              <v-expansion-panel>
                                <v-expansion-panel-header class="blue--text">
                                  Frequent Flyer and Optional Requests
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <v-row>
                                    <v-col cols="12" sm="6" md="4">
                                      <v-select
                                      v-model="passengerSeatType[n-1]"
                                      :items="['window seat', 'aisle row', 'exist row', 'extra leg room']"
                                      label="Seat Type"
                                      outlined
                                      prepend-inner-icon="mdi-seat-passenger"
                                      class="pa-0"
                                      height="56px"
                                      color="blue"
                                      id="passenger-seat"
                                      >
                                        <template v-slot:selection="{ item }">
                                          <span class="primary--text caption text-capitalize">{{item}}</span>
                                        </template>
                                      </v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                      <v-text-field
                                      height="60"
                                      v-model="passengerRedress[n-1]"
                                      label="TSA Redress #"
                                      outlined
                                      color="blue"
                                      :id="`passenger-${n}-redress-num`"
                                      >
                                        <template v-slot:append>
                                          <v-tooltip
                                          bottom
                                          color="primary white--text"
                                          >
                                            <template v-slot:activator="{ on }">
                                              <v-icon v-on="on">
                                                mdi-information-outline
                                              </v-icon>
                                            </template>
                                            This is a unique number
                                            the Department of Homeland Security(DHS)
                                            assigns to a passenger
                                            to promote resolution with
                                            previous watch list alerts.
                                          </v-tooltip>
                                        </template>
                                      </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                      <v-text-field
                                      height="60"
                                      v-model="passengerKnownNumber[n-1]"
                                      label="Known Traveller Number"
                                      outlined
                                      color="blue"
                                      :id="`passenger-${n}-known-traveller-num`"
                                      >
                                      </v-text-field>
                                    </v-col>
                                  </v-row>
                                  <p>Meals And Special Services</p>
                                  <v-row>
                                    <v-col cols="12" sm="6" v-if="!flight.isDomesticFlight">
                                      <v-select
                                      v-model="passengerMeal[n-1]"
                                      :items="['Inf/Baby Food',
                                      'Bland Meal',
                                      'Child Meal',
                                      'Diabetic Meal',
                                      'Gluten Free',
                                      'High Fiber Meal',
                                      'Hindu',
                                      'Kosher',
                                      'Low Calorie',
                                      'Low Carbohydrate',
                                      'Low Choles./Fat',
                                      'Low Protein',
                                      'Low Sodium',
                                      'Muslim Meal',
                                      'Nonlactose',
                                      'No Salt Meal',
                                      'Oriental',
                                      'Peanut Free Meal',
                                      'Low Purine Meal',
                                      'Raw Vegetarian',
                                      'Seafood Meal',
                                      'Spec Request',
                                      'Vegetarian',
                                      'Veg/Lacto-ovo']"
                                      label="Special Meal"
                                      outlined
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                      <v-select
                                      v-model="passengerSpecialService[n-1]"
                                      outlined
                                      label="Special Service"
                                      :items="['Blind Passenger',
                                      'Bassinet for Infant',
                                      'Deaf Passenger',
                                      'Stretcher Assistance',
                                      'Wheelchair - Cannot walk up stairs nor to the seat. Must be carried.',
                                      'Wheelchair - Can walk up stairs and to the seat',
                                      'Wheelchair - Cannot walk up stairs but is able to walk to the seat',]"
                                      >
                                      </v-select>
                                    </v-col>
                                  </v-row>
                                  <p>Frequent Flyers</p>
                                  <v-row>
                                    <v-col
                                    v-for="(item, x) in flight.marketingAirlines" :key="x"
                                    cols="12"
                                    sm="6"
                                    lg="4"
                                    >
                                      <v-text-field
                                      height="60"
                                      v-model="frequentFlyersNumbers[n-1][x].number"
                                      label="Frequent Flyer"
                                      outlined
                                      color="blue"
                                      :id="`passenger-${n}-ff-num`"
                                      >
                                        <template v-slot:prepend-inner>
                                          <v-tooltip
                                          bottom
                                          color="primary white--text"
                                          >
                                            <template v-slot:activator="{ on }">
                                              <img v-on="on" :src="item.airlineLogo" style="max-width: 30px; max-height: 30px;" :alt="item.airlineName">
                                            </template>
                                            {{item.airlineName}}
                                          </v-tooltip>
                                        </template>
                                      </v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </v-card-actions>
                        </v-card>
                      </v-form>
                      <v-row justify="center" class="my-5">
                        <v-btn x-large class="mx-5 px-15" color="white primary--text" @click="step = 1">Previous</v-btn>
                        <v-btn x-large class="mx-5 px-15" color="primary white--text" @click="nextClicked(2)">Next</v-btn>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="4">
                      <div class="countdown mb-5 d-none d-md-block">
                        <p><v-icon x-large color="white">mdi-alarm</v-icon> Book before the timeout</p>
                        <h2>
                          {{ timeLeft }}
                        </h2>
                      </div>

                      <v-card class="mb-5">
                        <v-card-title class="primary--text text-center text-bold heading-md ">
                          <p class="mx-auto my-0">Your Selected Trip</p>
                        </v-card-title>
                        <v-card-text class="px-0">
                          <div v-for="(segment, i) in flight.flightSegments" :key="i+'segment'">
                            <p class="grey lighten-2 paragraph--text text-center text-h6 font-weight-regular mb-0">{{i === flight.flightSegments.length - 1 ? 'Return' : 'Going'}}</p>
                            <div class="text-h6 font-weight-light text-center my-2">{{segment.leg.departureLocationCode + ' - ' + segment.leg.arrivalLocationCode}}</div>
                            <div class="body-1 font-weight-light text-center my-2">{{new Date(segment.leg.departureDate).toDateString() + ', ' + segment.leg.departureTime + ' - '
                              + new Date(segment.leg.arrivalDate).toDateString() + ', ' + segment.leg.arrivalTime}}</div>
                          </div>
                          <pricing :flight="flight" />
                          <p class="grey lighten-2 paragraph--text text-center text-h6 font-weight-regular mb-0">Total Amount</p>
                          <v-row justify="space-between" class="ma-5 mb-1">
                            <p class="body-1 paragraph--text font-weight-bold mb-0 text-right">Total Amount To Pay</p>
                            <p class="body-1 paragraph--text font-weight-bold mb-0 text-right"><span>${{flight.pricingInfo.newFare > 0 ? parseFloat(flight.pricingInfo.newFare).toFixed(2) : parseFloat(flight.pricingInfo.TotalFare).toFixed(2)}}</span> <sup class="caption error--text" v-if="flight.pricingInfo.discount_amount > 0">-${{flight.pricingInfo.discount_amount}}</sup></p>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
              <div v-show="step === 3">
                <v-container fluid class="px-15">
                  <v-row class="px-1">
                    <v-col cols="12" md="8">
                      <v-form v-model="valid2" ref="form2">
                        <address-card @setAddress="setAddress" v-if="paymentType === 'credit'" />
                        <v-card outlined class="pa-5 my-5">
                          <v-card-title><h3 class="paragraph--text font-weight-bold">Payment</h3></v-card-title>
                          <v-row>
                            <v-col cols="12" v-if="paymentType === 'points'">
                              <p class="body-1">You will be charged {{flight.pricingInfo.newFare > 0 ? flight.pricingInfo.newFare * 170 : flight.pricingInfo.TotalFare * 170}} points </p>
                            </v-col>
                          </v-row>
                          <credit v-if="paymentType === 'credit'" @holder="setCardHolder" @card="setCardData" @expire="setCardExpiry" @cvv="setCVV" />
                        </v-card>
                      </v-form>
                      <v-row justify="center" class="my-5">
                        <v-btn x-large class="mx-5 px-15" color="primary white--text" @click="nextClicked(3)">pay now</v-btn>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="4">
                      <div class="countdown mb-5 d-none d-md-block">
                          <p><v-icon x-large color="white">mdi-alarm</v-icon> Book before the timeout</p>
                          <h2>
                            {{ timeLeft }}
                          </h2>
                        </div>
                      <v-card class="mb-5">
                        <v-card-title class="primary--text text-center text-bold heading-md ">
                          <p class="mx-auto my-0">Your Selected Trip</p>
                        </v-card-title>
                        <v-card-text class="px-0">
                          <div v-for="(segment, i) in flight.flightSegments" :key="i+'segment'">
                            <p class="grey lighten-2 paragraph--text text-center text-h6 font-weight-regular mb-0">{{i === flight.flightSegments.length - 1 ? 'Return' : 'Going'}}</p>
                            <div class="text-h6 font-weight-light text-center my-2">{{segment.leg.departureLocationCode + ' - ' + segment.leg.arrivalLocationCode}}</div>
                            <div class="body-1 font-weight-light text-center my-2">{{new Date(segment.leg.departureDate).toDateString() + ', ' + segment.leg.departureTime + ' - '
                              + new Date(segment.leg.arrivalDate).toDateString() + ', ' + segment.leg.arrivalTime}}</div>
                          </div>
                          <pricing :flight="flight" />
                          <p class="grey lighten-2 paragraph--text text-center text-h6 font-weight-regular mb-0">Total Amount</p>
                          <v-row justify="space-between" class="ma-5 mb-1">
                            <p class="body-1 paragraph--text font-weight-bold mb-0 text-right">Total Amount To Pay</p>
                            <p class="body-1 paragraph--text font-weight-bold mb-0 text-right"><span>${{flight.pricingInfo.newFare > 0 ? parseFloat(flight.pricingInfo.newFare).toFixed(2) : parseFloat(flight.pricingInfo.TotalFare).toFixed(2)}}</span> <sup class="caption error--text" v-if="flight.pricingInfo.discount_amount > 0">-${{flight.pricingInfo.discount_amount}}</sup></p>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
              <div v-show="step === 4">
                <v-container>
                  <v-card elevation="3" :width="$vuetify.breakpoint.mdAndUp ? '40%' : '90%'" class="mx-auto pa-5">
                    <div class="text-center">
                      <v-icon size="72" color="success">mdi-check-circle</v-icon>
                    </div>
                    <h3 class="my-3 paragraph--text text-center">Payment Complete</h3>
                  </v-card>
                  <h2 class="my-10 paragraph--text text-center font-weight-light">Thank you, Your payment has been successful. A Confirmation has been sent to <span class="font-weight-bold">{{email}}</span></h2>
                  <v-card outlined class="pa-5">
                    <v-row>
                      <v-col v-for="(segment, i) in flight.flightSegments" :key="i+'segment'" cols="12" :md="flight.flightSegments.length === 1 ? 12 : (flight.flightSegments.length === 2 ? 6 : 4)">
                        <p class="grey lighten-2 paragraph--text text-center text-h6 font-weight-regular mb-0">{{i === flight.flightSegments.length - 1 ? 'Return' : 'Going'}}</p>
                        <div class="text-h6 font-weight-light text-center my-2">{{segment.leg.departureLocationCode + ' - ' + segment.leg.arrivalLocationCode}}</div>
                        <div class="body-1 font-weight-light text-center my-2">{{new Date(segment.leg.departureDate).toDateString() + ', ' + segment.leg.departureTime + ' - '
                          + new Date(segment.leg.arrivalDate).toDateString() + ', ' + segment.leg.arrivalTime}}</div>
                      </v-col>
                    </v-row>
                    <pricing :flight="flight" />
                  </v-card>
                  <v-btn
                    color="primary white--text px-15"
                    large
                    @click="$router.push({ name: 'Home' })"
                    class="my-5 d-block mx-auto"
                    elevation="0"
                  >
                    OK
                  </v-btn>
                </v-container>
              </div>
            </div>
          <v-row v-else>
            <v-col cols="12">
              <p class="display-3 primary--text">This flight is not available, please search again.</p>
            </v-col>
          </v-row>
          <v-dialog max-width="700" v-model="baggageDialog">
            <v-card>
                <div v-if="baggageRules.length > 0">
                  <div v-for="(item, i) in baggageRules" :key="i">
                    <v-card-title class="headline primary white--text font-weight-regular">
                      <span>
                        {{item.airlineName}}, <sub class="caption">{{item.airlineCode}} </sub>
                        <span>{{i === 0 || $store.state.flightType === 'multi' ? 'Departure' : 'Return'}}</span>
                        Baggage Rules
                      </span>
                    </v-card-title>
                    <a class="ma-3 blue--text" target="_blank" v-if="item.baggage_url" :href="item.baggage_url">Show All Baggage Rules</a>
                    <v-card-text v-if="item.freePieces">
                        <p class="headline text-center primary--text">Free Pieces</p>
                        <v-simple-table>
                            <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Number Of Pieces
                                    </th>
                                    <th class="text-left">
                                        Weight
                                    </th>
                                    <th class="text-left">
                                        Size
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-left">{{ item.freePieces.numberOfPieces }}</td>
                                    <td class="text-left">{{ item.freePieces.weight }}</td>
                                    <td class="text-left">{{ item.freePieces.size }}</td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                    <v-card-text v-if="item.additionalCost">
                        <v-divider class="mt-2 mb-5"></v-divider>
                        <p class="headline text-center primary--text">Additional Cost</p>
                        <v-simple-table>
                            <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Fees
                                    </th>
                                    <th class="text-left">
                                        Weight
                                    </th>
                                    <th class="text-left">
                                        Size
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-left">$ {{ item.additionalCost.fees }}</td>
                                    <td class="text-left">{{ item.additionalCost.weight }}</td>
                                    <td class="text-left">{{ item.additionalCost.size }}</td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                  </div>
                </div>
            </v-card>
          </v-dialog>
        </v-container>
    </div>
</template>

<script>
import { getFlight, createPNR, headersNoAuth } from '@/links'
import { countries } from '@/constants.js'
import overlay from '@/components/flights/overlay.vue'
import flightCard from '@/components/flights/card.vue'
import pricing from '@/components/flights/pricing.vue'
// import bookinData from '@/components/bookingDataTable'
import credit from '@/components/credit'
import phoneInput from '@/components/mobile.vue'
// import terms from './terms.vue'
import addressCard from '@/components/address.vue'
var intervalTimer

export default {
  title: '-Book',
  components: {
    overlay,
    flightCard,
    // bookinData,
    credit,
    phoneInput,
    // terms,
    pricing,
    addressCard
  },
  data () {
    return {
      applicationTitle: '',
      selectedTime: 0,
      timeLeft: '00:00',
      endTime: '0',
      el: 1,
      step: 1,
      showOverlay: false,
      paymentType: 'credit',
      nextLabel: 'Continue',
      menu1: [],
      menu2: [],
      valid1: true,
      valid2: true,
      loaded: true,
      snackbar: false,
      color: '',
      text: '',
      flight: null,
      flightResults: null,
      baggageRules: [],
      departures: [],
      arrivals: [],
      departDates: [],
      arriveDate: null,
      passengers: {},
      name: '',
      nameRules: [v => (!!v && v.length > 1) || 'Item is required at least 2 characters',
        v => /^[A-Za-z][A-Za-z]*$/.test(v) || 'Name Must be letters only with no spaces'
      ],
      middleNameRules: [
        v => (/^[A-Za-z][A-Za-z]*$/.test(v) || !v) || 'Name Must be letters only with no spaces'
      ],
      phone: '',
      phoneRules: [
        v => !!v || 'Phone is required',
        v => (!isNaN(v)) || 'Phone must be only numbers'
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/.test(v) || 'E-mail must be valid'
      ],
      zipRules: [
        v => !!v || 'Zip code is required',
        v => (!isNaN(v)) || 'Zip code must be only numbers'
      ],
      zip: '',
      requiredRule: [
        v => !!v || 'Field is required'
      ],
      passRules: [
        v => !!v || 'Passport number is required',
        v => v.length <= 15 || 'Maximum number of characters is 15',
        v => v.length >= 5 || 'Minimum number of characters is 5'
      ],
      address: '',
      streetLine: '',
      cardExpireDate: '',
      cardNum: '',
      cvv: '',
      cardCode: '',
      cardHolder: '',
      types: [
        { label: 'Adult', code: 'ADT' },
        { label: 'Child', code: 'CNN' },
        { label: 'Infant', code: 'INF' }
      ],
      baggageDialog: false,
      passengerType: [],
      passengerTitle: [],
      passengerGender: [],
      passengerSeatType: [],
      passengerRedress: [],
      passengerKnownNumber: [],
      passengerMeal: [],
      passengerSpecialService: [],
      search: [],
      passengerFirstName: [],
      passengerMiddleName: [],
      passengerLastName: [],
      dateOfBirth: [],
      passExpireDate: [],
      passExpireDateText: [],
      frequentFlyersNumbers: [],
      minExpireDate: '',
      passNum: [],
      issueCountry: [],
      goNext: true,
      pnr: '',
      totalPrice: '',
      currentPage: 0,
      travellersData: [],
      flightsOverlay: false,
      flightsTimeOut: null,
      disability_facility: '',
      termsIsRead: false,
      adamWarningIsRead: false,
      termsDialog: false,
      commDialog: false,
      netCommission: {
        type: '%',
        amount: '0'
      },
      lastFlightSegmentDate: '',
      passportExpiry: null,
      confirmData: {}
    }
  },
  watch: {
    el (newVal) {
      if (newVal === 2) {
        document.getElementById('card-address').addEventListener('input', (e) => {
          this.$store.dispatch('setWrittenAddress', e.target.value)
        })
      }
    }
  },
  computed: {
    showContactFields () {
      if (!this.$cookies.isKey('userToken')) return true
      else {
        if (this.$store.state.user) {
          if (this.$store.state.user.role === 'admin' || this.$store.state.user.role === 'subAgent') return true
          else return false
        } else return true
      }
    },
    requestHeaders () {
      return headersNoAuth()
    },
    flightCard () {
      const flights = []
      flights.push(this.flight)
      return flights
    },
    countries () {
      return countries
    },
    adultsNum () {
      return Number(this.passengers.adults)
    },
    childrenNum () {
      return Number(this.passengers.children)
    },
    infantsNum () {
      return Number(this.passengers.infants)
    },
    passengersNum () {
      return Number(this.passengers.adults) + Number(this.passengers.children) + Number(this.passengers.infants)
    },
    adultsMaxDate () {
      const today = new Date(this.lastFlightSegmentDate)
      const year = today.getFullYear()
      today.setDate(today.getDate())
      const max = today.setFullYear(year - 12)
      return max
    },
    childrenMaxDate () {
      const today = new Date(this.lastFlightSegmentDate)
      const year = today.getFullYear()
      today.setDate(today.getDate())
      const max = today.setFullYear(year - 2)
      return max
    },
    childrenMinDate () {
      const today = new Date(this.lastFlightSegmentDate)
      const year = today.getFullYear()
      today.setDate(today.getDate())
      const min = today.setFullYear(year - 12)
      return min
    },
    infantsMinDate () {
      const today = new Date(this.lastFlightSegmentDate)
      const year = today.getFullYear()
      today.setDate(today.getDate())
      const min = today.setFullYear(year - 2)
      return min
    },
    passportMinExpireDate () {
      const lastFlightSegment = this.flight.flightSegments[this.flight.flightSegments.length - 1]
      const lastArrivalDate = lastFlightSegment.leg.arrivalDate
      const today = new Date(lastArrivalDate)
      today.setMonth(today.getMonth() + 6)
      return today
    }
  },
  methods: {
    setAddress (address, zip, streetLine) {
      this.address = address
      this.zip = zip
      this.streetLine = streetLine
    },
    changeInputColor (id, color) {
      document.getElementById(id).style.borderColor = color
    },
    replaceFlight (flight) {
      this.snackbar = true
      this.color = 'success'
      this.text = 'Flight still avaialable'
      this.flight = flight
      clearTimeout(this.flightsTimeOut)
      this.flightsOverlay = false
      this.flightsTimeOut = setTimeout(() => {
        this.flightsOverlay = true
      }, 20 * 60 * 1000)
      this.setTime()
    },
    flightCheckFailed () {
      this.snackbar = true
      this.color = 'error'
      this.text = 'Flight is not avaialable any more'
    },
    getGrandTotal (price) {
      if (this.netCommission.amount && this.netCommission.amount > 0) {
        if (this.netCommission.type === '%') {
          return Number(price) + Number((price * this.netCommission.amount) / 100)
        } else {
          return Number(price) + Number(this.netCommission.amount)
        }
      } else {
        return Number(price)
      }
    },
    addRequest () {
      if (this.special_request) {
        this.special_requests.push(this.special_request)
        this.special_request = ''
      } else {
        this.snackbar = true
        this.color = 'error'
        this.text = 'Please provide a value before submitting'
      }
    },
    assignPhone (phone) {
      this.phone = phone
    },
    formatDate (date, i, type) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      const newDate = `${day} ${months[month - 1]} ${year}`
      if (type === 'passport') this.passExpireDateText[i] = newDate
      if (type === 'birthDate') return newDate.split(' ').reverse().join(' ')
    },
    adultsBirthDateRange (date) {
      return date > new Date(this.adultsMaxDate) || date < new Date('1900-1-1')
    },
    childrenBirthDateRange (date) {
      return date > new Date(this.childrenMaxDate) || date < new Date(this.childrenMinDate)
    },
    infantsBirthDateRange (date) {
      const today = new Date()
      return date > today || date < new Date(this.infantsMinDate)
    },
    minExpireDateRange (date) {
      return date < new Date(this.passportMinExpireDate)
    },
    setCardData (number, type) {
      this.cardNum = number
      switch (type) {
        case 'visa':
          this.cardCode = 'VI'
          break
        case 'mastercard':
          this.cardCode = 'CA'
          break
        case 'american-express':
          this.cardCode = 'AX'
          break
        case 'discover':
          this.cardCode = 'DS'
          break
        case 'diners-club':
          this.cardCode = 'DN'
          break
        default:
          this.cardCode = type
          break
      }
    },
    setCardExpiry (date) {
      this.cardExpireDate = date
    },
    setCVV (cvv) {
      this.cvv = cvv
    },
    setCardHolder (name) {
      this.cardHolder = name
    },
    classType () {
      let type
      switch (this.passengers.classType) {
        case 'Y':
          type = 'Economy'
          break
        case 'C':
          type = 'Business'
          break
        case 'F':
          type = 'First'
          break
        case 'S':
          type = 'Premium Economy'
          break
        default:
          break
      }
      return type
    },
    // addValue (n) {
    //   this.passNum[n] = ''
    // },
    book () {
      // if ((this.termsIsRead && this.paymentType === 'credit') || this.paymentType !== 'credit' || this.$store.state.mode === 'ats' || this.$store.state.mode === 'atsDev') {
      this.showOverlay = true
      const body = {
        flight_id: this.$route.params.id,
        search_id: this.$route.params.search,
        contact_person_name: this.name,
        contact_phone: this.phone.nationalNumber,
        countryIsoCode: this.phone.countryCode,
        contact_email: this.email,
        zipCode: this.zip,
        address: this.address,
        streetLine: this.streetLine,
        creditCardNumber: this.cardNum,
        creditCardExpireDate: this.cardExpireDate,
        creditCardType: this.cardCode,
        cvv: this.cvv,
        cardHolderName: this.cardHolder,
        passengerDetails: this.travellersData
      }
      // if (this.netCommission && this.netCommission.amount && this.netCommission.amount > 0) body.netCommission = this.netCommission
      // let header
      // if (this.$cookies.isKey('userToken') && this.$store.state.user.role && this.$store.state.user.role === 'customer') {
      if (!this.showContactFields) {
        delete body.contact_person_name
        delete body.contact_phone
        delete body.countryIsoCode
        delete body.contact_email
      }
      // header = {
      //   headers: headers(this.$cookies.get('userToken'))
      // }
      // } else if (this.$cookies.isKey('userToken')) {
      //   header = {
      //     headers: headers(this.$cookies.get('userToken'))
      //   }
      // } else header = { headers: headersNoAuth() }
      if (this.paymentType !== 'credit') {
        delete body.creditCardNumber
        delete body.creditCardExpireDate
        delete body.creditCardType
        delete body.cardHolderName
        delete body.cvv
        delete body.zipCode
        delete body.address
        this.paymentType === 'points' ? body.redeem = 'redeem' : body.cash = 'cash'
      }
      this.axios.post(createPNR, body, { headers: { Accept: 'application/json', 'Content-type': 'application/json' } }).then(response => {
        if (response.data.status === false) {
          this.showOverlay = false
          this.text = response.data.message
          this.color = 'error'
          this.goNext = false
          this.snackbar = true
        } else {
          this.showOverlay = false
          this.confirmData = response.data.data
          this.pnr = response.data.data.PNR
          this.totalPrice = response.data.data.totalPrice
          this.$store.dispatch('setFlightsTimeOut', false)
          localStorage.setItem('timeOut', false)
          if (this.$store.state.flightsTimeOutFunction) this.$store.dispatch('clearFlightsTimeoutFunction')
          if (this.$store.state.flightsSearchTimeOutFunction) this.$store.dispatch('clearFlightsSearchTimeoutFunction')
          window.scrollTo(0, 0)
          this.step = 4
          this.el = 3
        }
      }, () => {
        this.showOverlay = false
        this.snackbar = true
        this.goNext = false
        this.color = 'error'
        this.text = 'Something went wrong please try again in few minutes'
      })
      // } else {
      //   this.snackbar = true
      //   this.color = 'warning'
      //   this.text = 'Please read and agree on our terms and conditions!'
      // }
    },
    prepareTravellersData () {
      this.travellersData = []
      for (let index = 0; index < this.passengersNum; index++) {
        let type
        let title
        let gender
        if (this.passengerGender[index]) {
          this.passengerGender[index] === 'Male' ? gender = 'M' : gender = 'F'
        }
        if (this.adultsNum >= (index + 1)) {
          type = 'ADT'
          title = this.passengerTitle[index]
          if (this.passengerGender[index] === undefined) {
            if (title === 'Mr') {
              this.passengerGender[index] = 'M'
              gender = 'M'
            } else if (['Mrs', 'Ms', 'Miss'].indexOf(title) !== -1) {
              this.passengerGender[index] = 'F'
              gender = 'F'
            }
          }
        } else if (this.childrenNum >= ((index + 1) - this.adultsNum)) {
          type = 'CNN'
          title = 'CHD'
        } else if (this.infantsNum >= ((index + 1) - (this.adultsNum + this.childrenNum))) {
          type = 'INF'
          title = 'INF'
        }
        const frequentFlyer = []
        this.frequentFlyersNumbers[index].forEach(element => {
          if (element.number) frequentFlyer.push(element)
        })
        this.travellersData.push({
          passengerType: type,
          passengerTitle: title,
          passengerGender: gender,
          passengerFirstName: this.passengerFirstName[index],
          passengerMiddleName: this.passengerMiddleName[index],
          passengerLastName: this.passengerLastName[index],
          date_of_birth: this.getBirthdate(this.dateOfBirth[index]),
          passport_number: this.passNum[index] ? this.passNum[index] : '',
          passport_expire_date: this.passExpireDate[index] ? this.passExpireDate[index] : '',
          passport_issue_country: this.issueCountry[index] ? this.issueCountry[index] : '',
          seatSelectType: this.passengerSeatType[index] ? this.passengerSeatType[index] : 'not selected',
          tsaRedress: this.passengerRedress[index],
          frequentFlyer: frequentFlyer,
          passengerMeal: this.passengerMeal[index] ? this.passengerMeal[index] : '',
          passengerSpecialService: this.passengerSpecialService[index] ? this.passengerSpecialService[index] : '',
          passengerKnownNumber: this.passengerKnownNumber[index] ? this.passengerKnownNumber[index] : ''
        })
      }
    },
    getBirthdate (date) {
      if (date) {
        let newDate = ''
        const segments = date.replace(/\//g, '-').split('-')
        if (segments[0].length === 1) segments[0] = `0${segments[0]}`
        if (segments[1].length === 1) segments[1] = `0${segments[1]}`
        newDate = `${segments[0]}-${segments[1]}-${segments[2]}`
        return newDate
      }
    },
    nextClicked (currentPage) {
      try {
        if (currentPage === 2) {
          this.currentPage = 2
          window.scrollTo(0, 0)
          this.$refs.form1.validate()
          if (this.valid1) {
            const fullNames = []
            for (let index = 0; index < this.passengerFirstName.length; index++) {
              fullNames.push(`${this.passengerFirstName[index]} ${this.passengerLastName[index]}`)
            }
            if (new Set(fullNames).size === fullNames.length) {
              if (new Set(this.passNum).size === this.passNum.length || this.flight.isDomesticFlight) {
                if ((this.showContactFields && this.phone.valid) || !this.showContactFields) {
                  let passportExpireDateExists = true
                  for (let index = 0; index < this.passengerFirstName.length; index++) {
                    if (!this.passExpireDate[index]) passportExpireDateExists = false
                  }
                  if (passportExpireDateExists || this.flight.isDomesticFlight) {
                    let passportIssueCountryExists = true
                    for (let index = 0; index < this.passengerFirstName.length; index++) {
                      if (!this.issueCountry[index]) passportIssueCountryExists = false
                    }
                    if (passportIssueCountryExists || this.flight.isDomesticFlight) {
                      let birthDaysExists = true
                      for (let index = 0; index < this.passengerFirstName.length; index++) {
                        if (!this.dateOfBirth[index]) {
                          document.getElementsByClassName('mx-input').forEach((element, i) => {
                            if (!element.value) {
                              this.changeInputColor('birthDateId' + (i + 1), 'red')
                            }
                          })
                          birthDaysExists = false
                        }
                      }
                      if (birthDaysExists) {
                        this.snackbar = false
                        this.prepareTravellersData()
                        this.nextLabel = 'Book'
                        this.step = 3
                        this.el = 2
                        window.scrollTo(0, 0)
                      } else {
                        this.snackbar = true
                        this.color = 'error'
                        this.text = 'Please make sure to enter pasengers birth dates.'
                      }
                    } else {
                      this.snackbar = true
                      this.color = 'error'
                      this.text = 'Please make sure to enter passports issue country.'
                    }
                  } else {
                    this.snackbar = true
                    this.color = 'error'
                    this.text = 'Please make sure to enter passports expiry dates.'
                  }
                } else {
                  this.snackbar = true
                  this.color = 'error'
                  this.text = 'Please provide a valid phone number'
                }
              } else {
                this.snackbar = true
                this.color = 'warning'
                this.text = 'Passports numbers must be unique'
              }
            } else {
              this.snackbar = true
              this.color = 'warning'
              this.text = 'Please avoid using similar names for passengers'
            }
          } else {
            this.snackbar = true
            this.color = 'warning'
            this.text = 'Please fill all fields to continue'
          }
        } else if (currentPage === 3) {
          this.currentPage = 3
          this.$refs.form2.validate()
          if (this.valid2) {
            if (this.cardExpireDate) {
              if (!this.address && this.$store.state.writtenAddress) this.address = this.$store.state.writtenAddress
              if (!this.zip && this.$store.state.zipCode) this.zip = this.$store.state.zipCode
              if (this.address) {
                if (this.zip) {
                  this.book()
                } else {
                  this.snackbar = true
                  this.color = 'error'
                  this.text = 'Please Enter your ZIP code'
                }
              } else {
                this.snackbar = true
                this.color = 'error'
                this.text = 'Please Enter your address'
                document.getElementsByClassName('address-autocomplete')[0].style.borderColor = 'red'
              }
            } else {
              this.snackbar = true
              this.color = 'error'
              this.text = 'Please Enter credit card expire date'
              document.getElementsByClassName('mx-auto')[0].style.borderColor = 'red'
            }
          } else {
            this.snackbar = true
            this.color = 'warning'
            this.text = 'Please fill all fields to Book'
          }
        } else if (currentPage === 4) {
          this.currentPage = 4
          window.scrollTo(0, 0)
          this.$router.push({ name: 'Home' })
        }
      } catch (error) {}
    },
    backClicked (currentPage) {
      this.currentPage = currentPage
      if (currentPage === 3) {
        return false
      } else if (currentPage === 2) {
        window.scrollTo(0, 0)
        this.nextLabel = 'Continue'
        return true
      } else return true
    },
    goTo (page) {
      const routeData = this.$router.resolve({ name: page })
      window.open(routeData.href, '_blank')
    },
    closeTab () {
      // window.close()
      this.$router.push({ name: 'Home' })
    },
    setTime () {
      clearInterval(intervalTimer)
      this.timer(1200)
    },
    timer (seconds) {
      const now = Date.now()
      const end = now + seconds * 1000
      this.displayTimeLeft(seconds)

      this.selectedTime = seconds
      this.countdown(end)
    },
    countdown (end) {
      intervalTimer = setInterval(() => {
        const secondsLeft = Math.round((end - Date.now()) / 1000)

        if (secondsLeft === 0) {
          this.endTime = 0
        }

        if (secondsLeft < 0) {
          clearInterval(intervalTimer)
          return
        }
        this.displayTimeLeft(secondsLeft)
      }, 1000)
    },
    displayTimeLeft (secondsLeft) {
      const minutes = Math.floor((secondsLeft % 3600) / 60)
      const seconds = secondsLeft % 60

      this.timeLeft = `${zeroPadded(minutes)}:${zeroPadded(seconds)}`
    }
  },
  created () {
    // if (this.$store.state.mode === 'ats' || this.$store.state.mode === 'atsDev') {
    //   this.applicationTitle = 'ATSFares'
    // } else if (this.$store.state.mode === 'adam' || this.$store.state.mode === 'adamDev') {
    //   this.applicationTitle = 'AdamTravel'
    // } else {
    //   this.applicationTitle = 'Fare33'
    // }
    window.scrollTo(0, 0)
    if (this.$store.state.flightResults.flights === undefined) {
      this.$router.push({ path: '/' })
    } else {
      this.flightResults = this.$store.state.flightResults
      this.$store.state.flightDates.departureDates.forEach(item => {
        if (item !== null) {
          this.departDates.push(item)
        }
      })
      this.arriveDate = this.$store.state.flightDates.arrivalDate
      this.passengers = this.$store.state.passengers
      const passengersNum = Number(this.passengers.adults) + Number(this.passengers.children) + Number(this.passengers.infants)
      for (let i = 0; i < passengersNum; i++) {
        this.menu1[i] = false
        this.menu2[i] = false
        this.passNum.push('')
        this.passExpireDate.push(null)
        this.frequentFlyersNumbers.push([])
      }
      const id = this.$route.params.id
      const search = this.$route.params.search
      let query
      if (this.$route.params.relatedFlightId !== 'parent') {
        query = getFlight(id, search, this.$route.params.relatedFlightId)
      } else {
        query = getFlight(id, search)
      }
      const headers = { Accept: 'application/json', 'Content-type': 'application/json' }
      this.axios.get(query, { headers: headers }).then(response => {
        this.loaded = false
        if (response.data.status === true) {
          this.setTime()
          this.loaded = false
          this.flight = response.data.data
          this.lastFlightSegmentDate = this.$store.state.flightType === 'one' ? this.flight.flightSegments[this.flight.flightSegments.length - 1].leg.departureDate : this.flight.flightSegments[this.flight.flightSegments.length - 1].leg.arrivalDate
          this.frequentFlyersNumbers.forEach(list => {
            this.flight.marketingAirlines.forEach(item => {
              list.push({ airlineCode: item.airlineCode, number: null })
            })
          })
        } else {
          this.loaded = false
        }
      }, () => {
        this.loaded = false
        this.snackbar = true
        this.color = 'error'
        this.text = 'Something went wrong'
      })
      this.flightsTimeOut = setTimeout(() => {
        this.flightsOverlay = true
      }, 19 * 60 * 1000)
    }
  },
  beforeCreate () {
    if (this.$store.state.flightResults.flights === undefined) this.$router.push({ name: 'Home' })
  },
  beforeDestroy () {
    clearTimeout(this.flightsTimeOut)
    this.flightsOverlay = false
  }
}
function zeroPadded (num) {
  return num < 10 ? `0${num}` : num
}
</script>

<style>
  .background {
    height: auto;
    min-height: fit-content;
    background: linear-gradient(#ff8f95d3, #ff8f95d3), url('../../assets/images/sea.jpg');
    background-color: #333;
    background-size: 100% 100%;
    background-position: center center;
  }
  [v-cloak] { display: none; }
  .remove-controls input[type='number'] {
    -moz-appearance:textfield;
  }
  .remove-controls input::-webkit-outer-spin-button,
  .remove-controls input::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }
  /* .mx-input {
    width: 100%;
    height: 56px !important;
    border-color: #9e9e9e;
  } */
  .mx-datepicker {
    margin-bottom: 0px;
  }
  /* confirmartion */
  .confirm-card {
    padding: 20px;
    text-align: center;
    background-color: rgb(223, 223, 223);
    background-size: contain;
    background-position: top;
  }
  .confirm-card_overlay {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 700px;
    background-image: linear-gradient(45deg, #2f80c2, #0a3c65f0);
    padding: 40px;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    text-shadow: 0px 1px 3px rgb(35 35 35);
    padding: 20px 20%;
  }
  .confirm-card_overlay h3 {
    font-size: 40px;
  }
  .countdown {
    /* width: 200px;
    height: 100px; */
    border: 0.5px solid #9e9e9e;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    background: rgb(47,128,194);
    background: linear-gradient(135deg, #FF8F95 0%, #da3139 100%);
    color: #fff;
    font-size: 25px;
    font-weight: 500;
  }
  .countdown h2 {
    color: #fff;
    font-size: 65px;
  }
</style>
